export let VuePlyrControls = `
  <div class="plyr__controls">
    <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play">
        <svg class="icon--pressed" xmlns="http://www.w3.org/2000/svg" width="9" height="11.79" viewBox="0 0 9 11.79">
          <g transform="translate(-226.456 -635.5)">
            <path data-name="Path 297" d="M219.455,635.5v9.789" transform="translate(15 1)" fill="none" stroke="#0056b8" stroke-linecap="round" stroke-width="2"/>
            <path data-name="Path 298" d="M219.455,635.5v9.789" transform="translate(8 1)" fill="none" stroke="#0056b8" stroke-linecap="round" stroke-width="2"/>
          </g>
        </svg>
        <svg class="icon--not-pressed" xmlns="http://www.w3.org/2000/svg" width="10.219" height="13.843" viewBox="0 0 10.219 13.843">
          <path d="M1397.151,818.106v11.618a.224.224,0,0,0,.355.182l8.022-5.81a.224.224,0,0,0,0-.362l-8.022-5.809A.224.224,0,0,0,1397.151,818.106Z" transform="translate(-1396.276 -816.994)" fill="none" stroke="#0056b8" stroke-width="1.75"/>
        </svg>
        <span class="label--pressed plyr__tooltip" role="tooltip">Pause</span>
        <span class="label--not-pressed plyr__tooltip" role="tooltip">Play</span>
    </button>
    <div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
    <div class="plyr__progress">
        <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
        <span role="tooltip" class="plyr__tooltip">00:00</span>
    </div>
    <div class="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>
    <button type="button" class="plyr__control" aria-label="Mute" data-plyr="mute">
      <svg class="icon--pressed" xmlns="http://www.w3.org/2000/svg" width="13.846" height="11.559" viewBox="0 0 13.846 11.559">
        <g transform="translate(0.5 0.5)">
          <path d="M2818.507,14642.387v2.783h2.985l3.922,2.954v-10.559l-3.922,3.24h-2.985Z" transform="translate(-2818.507 -14637.565)" fill="#8893a4" stroke="#8893a4" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
          <path d="M2828.729,14643.026v4.852" transform="translate(8366.608 -12350.75) rotate(45)" fill="none" stroke="#8893a4" stroke-linecap="round" stroke-width="1"/>
          <path d="M2828.729,14643.026v4.852" transform="translate(-12345.188 -8350.324) rotate(-45)" fill="none" stroke="#8893a4" stroke-linecap="round" stroke-width="1"/>
        </g>
      </svg>
      <svg class="icon--not-pressed" xmlns="http://www.w3.org/2000/svg" width="12.923" height="11.559" viewBox="0 0 12.923 11.559">
        <g transform="translate(0.5 0.5)">
          <path d="M2818.507,14642.387v2.783h2.985l3.922,2.954v-10.559l-3.922,3.24h-2.985Z" transform="translate(-2818.507 -14637.565)" fill="#8893a4" stroke="#8893a4" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
          <path d="M2828.531,14640.938v3.9" transform="translate(-2819.244 -14637.631)" fill="none" stroke="#8893a4" stroke-linecap="round" stroke-width="1"/>
          <path d="M2828.729,14640.939v6.938" transform="translate(-2816.806 -14639.091)" fill="none" stroke="#8893a4" stroke-linecap="round" stroke-width="1"/>
        </g>
      </svg>
      <span class="label--pressed plyr__tooltip" role="tooltip">Unmute</span>
      <span class="label--not-pressed plyr__tooltip" role="tooltip">Mute</span>
    </button>
    <div class="plyr__volume">
        <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
    </div>
    <button type="button" class="plyr__control" data-plyr="captions">
        <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-captions-on"></use></svg>
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-captions-off"></use></svg>
        <span class="label--pressed plyr__tooltip" role="tooltip">Disable captions</span>
        <span class="label--not-pressed plyr__tooltip" role="tooltip">Enable captions</span>
    </button>
    <button class="plyr__controls__item plyr__control" type="button" data-plyr="pip">
      <svg xmlns="http://www.w3.org/2000/svg" width="13.271" height="13.711" viewBox="0 0 13.271 13.711">
        <g transform="translate(0.75 0.858)">
          <path d="M2.614,0,0,4.417" transform="translate(7.708 0.544) rotate(7)" fill="#fff" stroke="#0056b8" stroke-linecap="round" stroke-width="1.5"/>
          <path d="M2922.552,14638.423h-5.562v9.061h9.927v-5.694" transform="translate(-2916.99 -14635.381)" fill="none" stroke="#0056b8" stroke-linecap="round" stroke-width="1.5"/>
          <path d="M0,.944,3.176,0l.857,2.984" transform="translate(7.505 0) rotate(7)" fill="none" stroke="#0056b8" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        </g>
      </svg>        
      <span class="plyr__sr-only">PIP</span>
    </button>
    <button type="button" class="plyr__control" data-plyr="fullscreen">
        <svg class="icon--pressed" xmlns="http://www.w3.org/2000/svg" width="12.446" height="14.175" viewBox="0 0 12.446 14.175">
          <g transform="translate(-774.843 -636.864)">
            <g transform="translate(683.794 -365.92) rotate(45)">
              <path d="M0,.944,3.176,0l.857,2.984" transform="matrix(0.485, 0.875, -0.875, 0.485, 778.479, 642.465)" fill="none" stroke="#0056b8" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            </g>
            <g transform="translate(773.779 648.221) rotate(-45)">
              <path d="M0,2.04l3.175.944L4.033,0" transform="matrix(0.485, -0.875, 0.875, 0.485, 1.172, 3.527)" fill="none" stroke="#0056b8" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            </g>
            <g transform="translate(788.353 639.682) rotate(135)">
              <path d="M0,2.04l3.176.944L4.033,0" transform="matrix(0.485, -0.875, 0.875, 0.485, 1.172, 3.527)" fill="none" stroke="#0056b8" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            </g>
            <g transform="translate(784.836 651.738) rotate(-135)">
              <path d="M0,.944,3.175,0l.857,2.984" transform="matrix(0.485, 0.875, -0.875, 0.485, 3.782, 0)" fill="none" stroke="#0056b8" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            </g>
          </g>
        </svg>

        <svg class="icon--not-pressed" xmlns="http://www.w3.org/2000/svg" width="12.39" height="13.819" viewBox="0 0 12.39 13.819">
          <g transform="translate(1.012 0.858)">
            <path d="M2.614,0,0,4.417" transform="translate(6.565 0.544) rotate(7)" fill="#fff" stroke="#0056b8" stroke-linecap="round" stroke-width="1.5"/>
            <path d="M2.614,0,0,4.417" transform="translate(3.8 11.559) rotate(-173)" fill="#fff" stroke="#0056b8" stroke-linecap="round" stroke-width="1.5"/>
            <path d="M0,.944,3.176,0l.857,2.984" transform="translate(6.362 0) rotate(7)" fill="none" stroke="#0056b8" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M0,.944,3.176,0l.857,2.984" transform="translate(4.003 12.102) rotate(-173)" fill="none" stroke="#0056b8" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </g>
        </svg>

        <span class="label--pressed plyr__tooltip" role="tooltip">Exit fullscreen</span>
        <span class="label--not-pressed plyr__tooltip" role="tooltip">Enter fullscreen</span>
    </button>
</div>
`;