import Vue from "vue";
import VueAnalytics from 'vue-analytics'
import App from "./App.vue";
import VuePlyr from "./plugins/vue-plyr/";
import { VuePlyrControls } from "./plugins/vue-plyr/config";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.prototype.$hub = new Vue();
Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: 'UA-189940665-1'
})

Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: true },
    settings: [],
    controls: VuePlyrControls
  },
  emit: ["ended"]
});

new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
