<template>
  <div>
    <v-carousel hide-delimiters v-model="model" :height="mainHeight" @change="change()">
      <v-carousel-item v-for="(slide, i) in slides" :key="i" eager>
        <v-sheet tile class="sheet">
          <v-row
            class="fill-height"
            align="center"
            justify="center"
            :style="`height: ${mainHeight - 48}px;`"
          >
            <v-img
              v-if="slide.type === 'image'"
              eager
              contain
              :src="`slides/${slide.src}`"
              :style="`height: ${mainHeight - 48}px;`"
            ></v-img>
            <vue-plyr
              v-if="slide.type === 'video' && model === i"
              :ref="`plyr-${slide.src}`"
              :id="`plyr-${slide.src}`"
              class="video"
              @ended="ended(i)"
              :style="videoStyle"
            >
              <div
                data-plyr-provider="vimeo"
                :data-plyr-embed-id="slide.src"
                :style="videoStyle"
              ></div>
            </vue-plyr>
          </v-row>
        </v-sheet>
      </v-carousel-item>

    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: ["slides"],
  data: () => ({
    model: 0,
    mainHeight: 100,
    currentVideo: "",
    videoStyle: "",
    blockerHeight: 50
  }),
  methods: {
    setVideoStyle() {
      if (this.currentVideo) {
        const REF = "plyr-" + this.currentVideo;
        const REF_EL = document.getElementById(REF);
        const EL = REF_EL.getElementsByClassName("plyr")[0];
        const H = EL.getBoundingClientRect().height;
        const W = EL.getBoundingClientRect().width;
        const RATIO = H / W;
        const HEIGHT = this.mainHeight - 48;
        const WIDTH = HEIGHT / RATIO;
        this.videoStyle = `width: ${WIDTH}px;`;
      } else {
        this.videoStyle = `height: ${this.mainHeight - 48}px;`;
      }
    },
    setHeight() {
      const APP_BAR = document.getElementById("app-bar");
      const BUTTON_BAR = document.getElementById("button-bar");
      if (document.body.offsetHeight && APP_BAR && BUTTON_BAR) {
        let height = document.body.offsetHeight;
        height -= APP_BAR.getBoundingClientRect().height;
        height -= BUTTON_BAR.getBoundingClientRect().height;
        this.mainHeight = height;
        setTimeout(this.setVideoStyle, 100);
      } else {
        setTimeout(this.setHeight, 100);
      }
      setTimeout(() => {
        const EL = this.$refs.blocker;
        this.blockerHeight = EL.getBoundingClientRect().height;
      }, 10);
    },
    ended(i) {
      const SLIDE = this.slides[i];
      const ID = SLIDE.src;
      this.stopVideo(ID);
    },
    stopVideo(id) {
      const REF = "plyr-" + id;
      if (this.$refs[REF] && this.$refs[REF][0] && this.$refs[REF][0].player) {
        const PLAYER = this.$refs[REF][0].player;
        console.log(PLAYER);
        PLAYER.stop();
      }
    },
    playVideo(id) {
      const REF = "plyr-" + id;
      if (this.$refs[REF] && this.$refs[REF][0] && this.$refs[REF][0].player) {
        const PLAYER = this.$refs[REF][0].player;
        PLAYER.play();
      }
    },
    updateVideo() {
      if (this.currentVideo) {
        this.stopVideo(this.currentVideo);
        this.currentVideo = "";
      }
      const SLIDE = this.slides[this.model];
      if (SLIDE.type === "video") {
        this.currentVideo = SLIDE.src;
        if (this.currentVideo) {
          setTimeout(this.setVideoStyle(), 50);
          setTimeout(() => {
            this.playVideo(this.currentVideo);
          }, 500);
        }
      }
    },
    goToSlide(i) {
      this.model = i;
      this.$hub.$emit("slideChanged", this.model);
      setTimeout(this.updateVideo, 250);
    },
    change() {
      this.$hub.$emit("slideChanged", this.model);
      setTimeout(this.updateVideo, 250);
    },
  },
  mounted() {
    window.$car = this;
    this.setHeight();
    this.$hub.$on("goToSlide", this.goToSlide);
    window.addEventListener("resize", this.setHeight);
  },
  beforeDestroy() {
    this.$hub.$off("goToSlide");
    window.removeEventListener("resize", this.setHeight);
  },
};
</script>

<style lang="scss">
.sheet {
  background-color: transparent !important;
}
.v-carousel__controls {
  text-align: center;
  .v-item-group {
    .v-btn--icon.v-size--small {
      height: 18px;
      width: 18px;
      .v-icon {
        font-size: 14px !important;
      }
    }
  }
  .v-btn--icon.v-size--small.v-btn--active .v-icon {
    font-size: 20px !important;
    opacity: 1;
  }
}
.video {
  width: 100vw;
  .plyr__controls {
    width: 100%;
    padding: 0 20px !important;
    .plyr__volume,
    .plyr__control[data-plyr="mute"],
    .plyr__control[data-plyr="fullscreen"] {
      display: none;
    }
    .plyr__control[data-plyr="play"],
    .plyr__time {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
.click-blocker {
  opacity: 0;
  background-color: white;
  width: 100vw;
  height: 50px;
  position: absolute;
  bottom: 0;
  z-index: 100000;
}
</style>
