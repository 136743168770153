<template>
  <v-app>
    <v-app-bar app dense color="white" elevation="1" id="app-bar">
      <div class="d-flex align-center">
        <v-img
          alt="Cello logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo-single.png')"
          transition="scale-transition"
        />
      </div>
    </v-app-bar>
    <v-main id="main">
      <div id="button-bar">
        <v-btn
          v-for="(bookmark, i) in bookmarks"
          :key="i"
          small
          class="mx-2 my-1"
          elevation="2"
          :color="i === activeBookmark ? '#0f1637' : '#f8971c'"
          :dark="i === activeBookmark"
          @click="bookmarkClicked(bookmark.slide)"
        >
          {{ bookmark.title }}
        </v-btn>
      </div>
      <Carousel :slides="slides" />
    </v-main>
  </v-app>
</template>

<script>
import slideData from "./slides.json";
import Carousel from "./components/Carousel";
export default {
  name: "App",
  components: {
    Carousel,
  },
  data: () => ({
    slides: slideData.slides,
    bookmarks: slideData.bookmarks,
    current: 0,
    activeBookmark: -1,
  }),
  methods: {
    bookmarkClicked(slide) {
      this.$hub.$emit("goToSlide", slide);
    },
    updateActiveBookmark() {
      let active = -1;
      for (let i = 0; i < this.bookmarks.length; i++) {
        const BKMK = this.bookmarks[i];
        if (this.current >= BKMK.slide) {
          active = i;
        }
      }
      this.activeBookmark = active;
    },
    slideChanged(num) {
      this.current = num;
      this.updateActiveBookmark();
    },
  },
  mounted() {
    window.$app = this;
    this.$hub.$on("slideChanged", this.slideChanged);
    this.$ga.page('/');
  },
  beforeDestroy() {
    this.$hub.$off("slideChanged");
  },
};
</script>

<style lang="scss">
html, body {
  height: 100vh;
  overflow-y: hidden;
}
#main {
  background-image: url("./assets/bg.jpg");
  background-size: cover;
  overflow-y: hidden;
  #button-bar {
    width: 100%;
    text-align: center;
    padding: 10px 55px;
    .active-button {
      background-color: blue !important;
    }
  }
}
</style>
